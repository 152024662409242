<template>
  <a-modal title="导入证书"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :width="600"
           :centered="true"
           ok-text="提交"
           @ok="confirm"
           @cancel="close()">
    <a-form :form="form"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="业务类型">
        <a-select v-decorator="['type', decorator.type]"
                  placeholder="请选择业务类型"
                  @change="getTemplates($event)">
          <a-select-option v-for="(item) in trainingTypeList"
                           :key="item.id"
                           :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="证书模版">
        <div class="flex space-between align-start" style="padding-top: 5px;">
          <a-select v-decorator="['templateId', decorator.templateId]"
                    placeholder="请选择证书模版"
                    @change="templateChange">
            <a-select-option v-for="(item) in templates"
                             :key="item.id"
                             :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
          <div class="ml-20 template-img-wrapper">
            <img v-if="templateImg"
                 :src="templateImg"
                 alt=""
                 class="template-img"
                 @click="previewImage">
            <span v-else class="empty no-padding">模板预览图</span>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="选择本地文件">
        <a-upload v-decorator="['fileList', decorator.fileList]"
                  name="file"
                  :multiple="false"
                  :before-upload="getLocalFile"
                  @change="uploadChange">
          <a-button>上传文件</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label=" " :colon="false">
        <span>点击</span>
        <a :href="templateUrl" target="_blank">这里</a>
        <span>下载证书导入模版</span>
      </a-form-item>
    </a-form>

    <ImageViewer ref="imageViewerRef"/>
  </a-modal>
</template>

<script>
import axios from 'axios';
import {
  getTemplates, importExcel,
} from '../api';

import ImageViewer from '../../../../components/ImageViewer.vue';

export default {
  name: 'ImportCertificateModal',
  components: {
    ImageViewer,
  },
  data() {
    return {
      loading: false,
      shown: false,

      decorator: {
        type: {
          rules: [{ required: true, message: '请选择业务类型' }],
        },
        templateId: {
          rules: [{ required: true, message: '请选择证书模版' }],
        },
        fileList: {
          valuePropName: 'fileList',
          getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
          rules: [{ required: true, message: '请上传文件' }],
        },
      },

      type: 2,
      trainingTypeList: [
        { id: 2, name: '任务' },
        { id: 3, name: '培训' },
      ],

      templateImg: '',
      templateUrl: '',
      templates: [],
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ type, templateId } = {}) {
      this.templateUrl = '';

      this.fileList = [];

      this.getTemplates(type || 2);

      this.shown = true;

      this.getFieldDecorator();
      this.form.setFieldsValue({
        type: type || 2,
        templateId: templateId || undefined,
        fileList: [],
      });
    },
    close() {
      this.shown = false;
    },
    getFieldDecorator() {
      Object.keys(this.decorator).forEach((key) => {
        this.form.getFieldDecorator(key, this.decorator[key]);
      });
    },

    confirm() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = new FormData();
          params.append('type', values.type);
          params.append('certificate_template_id', values.templateId);
          // ??? originFileObj ???
          params.append('file', values.fileList[0]?.originFileObj || values.fileList[0]);
          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await importExcel(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '上传失败');
      } else {
        this.$message.success(data?.message || '上传成功');
      }
      this.$emit('success');

      this.close();
    },

    /**
     * 获取证书模版
     * @returns {Promise<void>}
     */
    async getTemplates(type) {
      if (this.cancelGetTemplates) {
        this.cancelGetTemplates();
        this.cancelGetTemplates = null;
      }
      this.loading = true;
      const data = await getTemplates({
        type,
      }, new axios.CancelToken((c) => {
        this.cancelGetTemplates = c;
      })).finally(() => {
        this.loading = false;
      });

      this.templates = data?.data || data?.info || [];
      this.templateUrl = data?.meta?.certificate_config_excel_url || '';
    },

    templateChange(evt) {
      this.templateImg = this.templates.filter(
        (i) => i.id === evt,
      )?.[0]?.templates_file_url || '';
    },

    previewImage() {
      if (this.$refs.imageViewerRef?.show) {
        this.$refs.imageViewerRef.show({ url: this.templateImg });
      }
    },

    getLocalFile(evt) {
      this.form.setFieldsValue({
        fileList: [evt],
      });

      return false;
    },
    uploadChange(evt) {
      if (evt?.file?.status === 'removed') {
        this.form.setFieldsValue({
          fileList: [],
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.template-img-wrapper {
  width: 100px;
  height: 100px;
  text-align: center;
  background-color: #DFDFDF;

  .template-img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
