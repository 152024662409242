import request from '@/utils/Request';

// 证书导入记录列表
export function getImportRecords(params) {
  return request({
    url: '/certificate/admin/batchImport/importLogList',
    method: 'get',
    params,
  });
}

// 证书模板列表
export function getTemplates(params, cancelToken) {
  return request({
    url: '/certificate/admin/batchImport/certificateTemplateList',
    method: 'get',
    params,
    cancelToken,
  });
}

// 表格导入
export function importExcel(data) {
  return request({
    url: '/certificate/admin/batchImport/importUserExecl',
    method: 'post',
    data,
  });
}

// 修改证书图片
export function updateCertificateFile(data) {
  return request({
    url: '/certificate/admin/batchImport/updCertificateFile',
    method: 'post',
    data,
  });
}
